import React from "react";
import InstructorNavbar from "./InstructorNavbar";
import "../../css/InstructorStyles.css";

export default function InstructorInstructions() {
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar wrapperClass="left-navbar"
          menuItemClass="nav-button"/>
        <div className="instruction-container">
          <div className="instructions-heading" style={{ textAlign: "left" }}>
            <h1 style={{ textAlign: "left" }}>Instructions</h1>
            <div className="instructor-instruction">
              <p>This section is to put useful and actionable instructions.</p>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
}
